<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex class="mb-5">
        <v-card elevation="4" width="500" class="mx-auto">
          <v-card-title primary-title>
            <h4 class="headline mb-0" style="color: gray">
              <b>أهداف المندوب</b>
            </h4>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-layout row-wrap>
                <v-flex xs12 md8 v-if="role != 'Salesman'">
                  <v-select
                    v-model="month"
                    :items="months"
                    label="الشهر"
                    persistent-hint
                    required
                  ></v-select>
                </v-flex>

                <v-flex xs12 md8>
                  <v-select
                    v-model="year"
                    :items="years"
                    @change="getTargets"
                    label="السنة"
                    persistent-hint
                    required
                  ></v-select>
                </v-flex>
                <v-flex xs12 md8 v-if="role != 'Salesman'">
                  <v-btn style="background: #58dd5b; color: white" @click="addTagets">
                    <b>تغيير</b>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout wrap row v-if="role != 'Salesman'">
                <v-flex xs12 md8>
                  <v-text-field
                    :rules="[(v) => !!v || 'الهدف مطلوب']"
                    label="الهدف"
                    placeholder="الهدف"
                    v-model="target"
                    type="number"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>

            <v-layout wrap row>
              <v-flex>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">الشهر</th>
                        <th class="text-center">الهدف</th>
                      </tr>
                    </thead>
                    <tbody v-if="allTargets.length > 0">
                      <tr
                        @click="setTagets(item)"
                        v-for="(item, index) in allTargets"
                        :key="index"
                      >
                        <td class="text-center">{{ item.month }}</td>
                        <td class="text-center">{{ item.target }}</td>
                      </tr>
                    </tbody>

                    <tbody v-else>
                      <tr>
                        <td colspan="2" class="text-center">لا توجد بيانات</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Targets",
  data() {
    return {
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      month: 0,
      years: [],
      year: 0,
      target: "",
      allTargets: [],
      valid: true,
    };
  },
  methods: {
    async getTargets() {
      let startDate = new Date(this.year + "-01-01").toISOString().substr(0, 10);
      let endDate = new Date(this.year + "-12-01").toISOString().substr(0, 10);
      let url = "target/GetMonthlyTargets?start=" + startDate + "&end=" + endDate;
      await this.ApiService.get(url)

        .then((res) => {
        
          this.allTargets = [];
          var all = res.data.responseData;

          let k = 1;
          all.forEach((el) => {
            if (el != null) {
              this.allTargets.push({ month: k, target: el.visits });
              k++;
            }
          });
        })
        .catch(() => {});
    },
    async addTagets() {
      if (this.$refs.form.validate()) {
        await this.ApiService.post("target/AddTarget?user=" + this.user.id, {
          Visits: this.target,
          Date: new Date(this.year + "-" + this.month + "-02")
            .toISOString()
            .substr(0, 10),
        })
          .then(() => {
            this.resetValues();
            this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
          })
          .catch(() => {
            this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
          });
      }
    },
    setTagets(item) {
      this.month = item.month;
      this.target = item.target;
    },
    resetValues() {
      this.target = "";
      this.valid = true;
      this.getTargets();
    },
    getYears() {
      let y = 0;
      while (y <= 5) {
        this.years.push(this.year + y);
        y++;
      }
    },
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    var d = new Date();
    //var date = d.getDate();
    this.month = d.getMonth() + 1;
    this.year = d.getFullYear();
    this.getTargets();
    this.getYears();
  },
};
</script>
